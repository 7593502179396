import React from 'react'
import { Helmet } from "react-helmet"
import { Card, CardBody, Button } from 'reactstrap'
import DashboardLayout from '../layouts/dashboardlayout'
import logo from '../assets/img/Brunch_Logo.png'
import { navigate } from 'gatsby'
const Error = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - 404</title>
      </Helmet>
      <DashboardLayout>
        <div className="backgroundImg">
        <Card style={{ width: "49%" }}>
          <CardBody>
            <img src={logo} />
            <p className="fontFile">404</p>
            <p style={{ textAlign: "center", fontWeight: "800", color: "#000", fontSize: "18px" }}>Oops, this page hasn't been found</p>
            <Button className="btn-vino btn-vino-primary" onClick={() => navigate(`/`)}> Take me home</Button>
          </CardBody>
        </Card>
      </div>
      </DashboardLayout>
      </>
  )
}

export default Error
